/*!
 * Copyright ou © ou Copr. Université de Lorraine, (2022)
 *
 * Direction du Numérique de l'Université de Lorraine - SIED
 *  (dn-mobile-dev@univ-lorraine.fr)
 * JNESIS (contact@jnesis.com)
 *
 * Ce logiciel est un programme informatique servant à rendre accessible
 * sur mobile divers services universitaires aux étudiants et aux personnels
 * de l'université.
 *
 * Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 * et respectant les principes de diffusion des logiciels libres. Vous pouvez
 * utiliser, modifier et/ou redistribuer ce programme sous les conditions
 * de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 * sur le site "http://cecill.info".
 *
 * En contrepartie de l'accessibilité au code source et des droits de copie,
 * de modification et de redistribution accordés par cette licence, il n'est
 * offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 * seule une responsabilité restreinte pèse sur l'auteur du programme, le
 * titulaire des droits patrimoniaux et les concédants successifs.
 *
 * À cet égard, l'attention de l'utilisateur est attirée sur les risques
 * associés au chargement, à l'utilisation, à la modification et/ou au
 * développement et à la reproduction du logiciel par l'utilisateur étant
 * donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 * manipuler et qui le réserve donc à des développeurs et des professionnels
 * avertis possédant des connaissances informatiques approfondies. Les
 * utilisateurs sont donc invités à charger et à tester l'adéquation du
 * logiciel à leurs besoins dans des conditions permettant d'assurer la
 * sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 * à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 * Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 * pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 * termes.
 */

// // // CSS classes (Use this css classes in HTML tags described below throughout the app).


// // // ---------------------
// // // Simple text button
// // // ---------------------

// Button text type 1 with arrow icon on the right. Use it on ion-button tags
// <ion-button (click)="onClick()" class="app-text-button-1">
//   <ion-text class="app-text-5"> {{ item.label }} </ion-text>
//   <ion-icon class="app-icon-5" name="arrow-right"></ion-icon>
// </ion-button>
.app-text-button-1 {
  text-transform: var(--app-text-button-1-text-transform);
  --background: var(--app-text-button-1-background) !important;
  --box-shadow: var(--app-text-button-1-box-shadow);
  color: var(--app-text-button-1-color);
  max-height: var(--app-text-button-1-max-height);

  ion-icon {
  margin-left: var(--app-text-button-1-icon-margin-left) !important;
  }
}

// Button text type 2 with rotated arrow icon on the right. The icon will rotate when you click on the button. Use it on ion-button tags
// Example:

// <ion-button class="app-text-button-2 app-text-5" (click)="toggleExpandGroups()">
//   <ion-text>My button</ion-text>
//   <ion-icon [class.content-open]="!opened" [class.content-close]="opened" name="arrow-right"></ion-icon>
// </ion-button>
.app-text-button-2 {
  text-transform: var(--app-text-button-2-text-transform);
  --background: var(--ion-color-primary) !important;
  --box-shadow: var(--app-text-button-2-box-shadow);
  color: var(--ion-color-primary-contrast);
  max-height: var(--app-text-button-2-max-height);

  ion-icon {
    margin-left: var(--app-text-button-2-icon-margin-left) !important;
    color: var(--app-text-button-2-color) !important;
  }

  ion-icon.content-open {
    transform: rotate(90deg);
  }

  ion-icon.content-close {
    transform: rotate(-90deg);
  }
}

// // // ---------------------
// // // Rectangular Button
// // // ---------------------

// Button Rectangular type 1 with border and outline bottom. Use it on ion-button tags
// like this exemple (respect dom and class) :

// <ion-button class="app-rectangle-button-1" lines="none">
// <ion-label>Label</ion-label>
// </ion-button>

.app-rectangle-button-1 {
  --background: var(--app-rectangular-button-1-background-color);
  --background-activated: var(--app-rectangular-button-1-background-color);
  //border-bottom: var(--app-rectangular-button-1-bottom-border-width) solid var(--app-rectangular-button-1-border-bottom-color);
  //outline: var(--app-rectangular-button-1-outline-width) solid var(--app-rectangular-button-1-outline-color);
  --box-shadow: none;
  --border-radius: 0px !important;
  --padding-bottom: 0.5rem;
  --padding-top: 0.5rem;
  min-height: var(--app-rectangular-button-1-min-height);

  ion-label {
    color: var(--app-rectangular-button-1-font-color);
    margin-top:  var(--app-rectangular-button-1-bottom-border-width);
    font-family: var(--app-rectangular-button-1-font-family);
    font-size: var(--app-rectangular-button-1-font-size);
    text-transform: uppercase;
    letter-spacing: 0.052rem;
  }
}

// // // ---------------------
// // // Square Button with label below
// // // ---------------------

// Button Square type 1 (with label below)
// app-square-button-1 css class is for square button with ion-icon only
// and label below. Use it like this exemple (respect dom and class) :

// <div class="app-square-button-1" (click)="action()">
//   <div class="icon-container">
//     <ion-icon name="icon-name"></ion-icon>
//   </div>
//   <ion-label>{{menuItem.title}}</ion-label>
// </div>


.app-square-button-1 {
  width: 6rem;
  height: 9rem;
  overflow: visible;
  margin: auto;
  background: none;
  box-shadow: none;
  user-select: none;
  justify-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .icon-container {
    padding: 0;
    background-color: var(--app-square-button-1-background-color);
    border-radius: var(--app-square-button-1-border-radius) !important;
    height: 5rem;
    width: 5rem;
    display: grid;
    align-content: center;
    justify-content: center;

    ion-icon {
      // @TODO color A supprimer une fois que la couleur viendra de directus
      color: var(--ion-color-primary);
      font-size: var(--app-square-button-1-icon-size) !important;
    }
  }

  ion-label {
    user-select: none;
    font-family: var(--app-square-button-1-font-family);
    font-weight: var(--app-square-button-1-font-weight);
    color: var(--app-square-button-1-font-color);
    letter-spacing: var(--app-square-button-1-letter-spacing);
    font-size: var(--app-square-button-1-font-size);
    margin-top: var(--app-square-button-1-label-margin-top);
    line-height: var(--app-square-button-1-label-line-height);
  }
}

// // // ---------------------
// // // Rounded Button
// // // ---------------------

// Button Rounded type 1
// app-round-button-1 css class is for round button with ion-icon only or short text. Use it like this exemple (respect dom and class) :

// <ion-button shape="round" class="app-round-button-1 ion-no-padding ion-no-margin" color="light">
//   <ion-icon name="moon" slot="icon-only" size="small"></ion-icon> //or text
// </ion-button>

.app-round-button-1 {
  height: var(--app-rounded-button-1-height);
  width: var(--app-rounded-button-1-width);
  border: var(--app-rounded-button-1-border-width) solid var(--ion-color-primary) !important;
  border-radius: 50%;
  --box-shadow: 0px;
  --background: var(--app-rounded-button-1-background-color) !important;
  min-height: var(--app-rounded-button-1-height);
  box-sizing: content-box;

  ion-icon {
    font-size: var(--app-rounded-button-1-icon-size);
    color: var(--app-rounded-button-1-icon-color) !important;
  }
}
