/*!
 * Copyright ou © ou Copr. Université de Lorraine, (2022)
 *
 * Direction du Numérique de l'Université de Lorraine - SIED
 *  (dn-mobile-dev@univ-lorraine.fr)
 * JNESIS (contact@jnesis.com)
 *
 * Ce logiciel est un programme informatique servant à rendre accessible
 * sur mobile divers services universitaires aux étudiants et aux personnels
 * de l'université.
 *
 * Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 * et respectant les principes de diffusion des logiciels libres. Vous pouvez
 * utiliser, modifier et/ou redistribuer ce programme sous les conditions
 * de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 * sur le site "http://cecill.info".
 *
 * En contrepartie de l'accessibilité au code source et des droits de copie,
 * de modification et de redistribution accordés par cette licence, il n'est
 * offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 * seule une responsabilité restreinte pèse sur l'auteur du programme, le
 * titulaire des droits patrimoniaux et les concédants successifs.
 *
 * À cet égard, l'attention de l'utilisateur est attirée sur les risques
 * associés au chargement, à l'utilisation, à la modification et/ou au
 * développement et à la reproduction du logiciel par l'utilisateur étant
 * donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 * manipuler et qui le réserve donc à des développeurs et des professionnels
 * avertis possédant des connaissances informatiques approfondies. Les
 * utilisateurs sont donc invités à charger et à tester l'adéquation du
 * logiciel à leurs besoins dans des conditions permettant d'assurer la
 * sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 * à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 * Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 * pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 * termes.
 */

// Week style

.fc-timeGridWeek-view {
  width: 130% !important;
  transform-origin: top left;
  overflow-x: scroll;

  .week-view-column-header-day {
    font-size: 0.67rem !important;
  }

  .week-view-column-header-number {
    font-size: 1.1rem !important;
  }

  .fc-col-header,
  .fc-timegrid-body,
  .fc-scrollgrid-sync-table,
  .fc-timegrid-cols,
  .fc-timegrid-slots {
    width: 100% !important;
  }

  .fc-timegrid-cols table,
  .fc-timegrid-slots table {
    width: 100% !important;
  }
}

.fc-timeGridWeek-view.zoom1 {
  transform: scale(1.1);

  .fc-view-harness-active,
  .fc-scroller-harness-liquid,
  .fc-scrollgrid-section-body {
    height: 91% !important;
  }
}


.fc-timeGridWeek-view.zoom2 {
  transform: scale(1.3);

  .fc-view-harness,
  .fc-view-harness-active,
  .fc-scroller-harness-liquid,
  .fc-scrollgrid-section-body {
    height: 76% !important;
  }
}



// Month style

.fc-dayGridMonth-view {
  overflow-x: scroll;
  width: 130% !important;
  transition: transform 0.3s ease-in-out;
  transform-origin: top left;
  height: 100%;

  .fc-daygrid-body,
  .fc-scrollgrid-sync-table,
  .fc-col-header {
    width: 100% !important;
  }

  .fc-view-harness,
  .fc-view-harness-active,
  .fc-scroller-harness-liquid,
  .fc-scrollgrid-section-body {
    transition: height 0.3s ease-in-out;
    height: 100% !important;
  }
}

.fc-dayGridMonth-view.zoom1 {
  transform: scale(1.2);

  .fc-view-harness,
  .fc-view-harness-active,
  .fc-scroller-harness-liquid,
  .fc-scrollgrid-section-body {
    height: 90% !important;
  }
}

.fc-dayGridMonth-view.zoom2 {
  transform: scale(1.4);

  .fc-view-harness,
  .fc-view-harness-active,
  .fc-scroller-harness-liquid,
  .fc-scrollgrid-section-body {
    height: 80% !important;
  }
}

.fc-daygrid-day {
  background-color: white;
}

.fc-day-disabled {
  background: var(-ion-color-light-shade) !important;
}

.fc-daygrid-day {
  border: none !important;
  border-bottom: 1px solid var(--ion-color-medium) !important;
}

.fc-daygrid-day-number {
  font-size: 0.8rem;
  color: var(--app-font-color-primary);
}

.fc-daygrid-event-harness {
  margin-bottom: 5px !important;
}

.fc-daygrid-day-top {
  background-color: var(--ion-color-light-tint) !important;
}

.fc-v-event .fc-event-main {
  color: red !important;
}

.fc-col-header-cell-cushion {
  color: var(--app-font-color-primary) !important;
}



// Week/Month common style

.fc-view-harness-active {
  overflow-x: scroll;
}

.fc-view-harness {
  overflow-y: hidden !important;

  @supports (margin-right: env(safe-area-inset-right)) {
    margin-right: env(safe-area-inset-right)
  }

  @supports (margin-left: env(safe-area-inset-left)) {
    margin-left: env(safe-area-inset-left)
  }
}

// Week / Day common style

.fc-timegrid-slot-label {
  font-size: 0.8rem;
}

.fc-timegrid-event {
  border: none !important;
  box-shadow: none !important;
}

.fc-timegrid-event .fc-event-main {
  padding: 0 !important;
}

.fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  border-width: 1px 1px 1px 0px !important;
  border-radius: 0px 3px 3px 0px !important;
  height: 4px;
  width: 25px;
  background-color: var(--ion-color-medium-light);
  opacity: 1 !important;
  z-index: 0;
}

.fc .fc-timegrid-now-indicator-arrow {
  margin-top: -3px !important;
  border-style: solid !important;
  border-color: var(--ion-color-medium-light) !important;
}

.dark {
  .fc .fc-timegrid-now-indicator-arrow {
    border-color: var(--ion-color-light) !important;
  }
}

.fc .fc-timegrid-now-indicator-line {
  z-index: 4 !important;
  margin-top: -2px !important;
  border-style: solid !important;
  border-color: var(--ion-color-light) !important;
  border-color: var(— fc-now-indicator-color, var(--ion-color-light)) !important;
  border-width: 1px 1px 1px 0px !important;
  border-radius: 0px 3px 3px 0px !important;
  height: 2px;
  background-color: var(--ion-color-light);
  opacity: 0.5 !important;
}

// Month / Week / Day common style

.fc-timegrid-event-harness {
  margin-top: 1px;
  margin-right: 0.04rem;
  margin-left: 0.04rem;
}

.fc-view-harness {
  font-family: var(--app-font-secondary);
}

.fc .fc-button {
  background-color: rgb(0, 0, 0) !important;
  border: none !important;
  border-radius: 0em !important;
  font-size: 0.8rem !important;
  padding: 0.3em 0.40em !important;
}

.fc-button-today {
  background-color: rgb(0, 0, 0) !important;
}

.fc .fc-button:disabled {
  display: none;
}

.fc-button-group {
  margin-right: 1rem !important;

}

.fc-prev-button {
  margin-right: 0.65rem !important;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0rem !important;
  border-bottom: 1px solid var(--ion-color-medium) !important;

  @supports (padding-right: env(safe-area-inset-right)) {
    padding-right: env(safe-area-inset-right)
  }

  @supports (padding-left: env(safe-area-inset-left)) {
    padding-left: env(safe-area-inset-left)
  }
}

.fc-toolbar-title:first-letter {
  text-transform: uppercase;
}

.fc .fc-toolbar-title {
  margin: 0.8rem !important;
  margin-left: 1.3rem !important;
  max-width: 100%;
  font-size: var(--app-title-3-size) !important;
  font-family: var(--app-font-primary-static-bold);
  //border-bottom-width: var(--app-border-2-width);
  //border-bottom-style: solid;
  //border-bottom-color: var(--ion-color-secondary);
}

.fc-col-header-cell-cushion:first-letter {
  text-transform: uppercase !important;
}

.fc-day-today {
  background-color: var(--ion-color-tertiary) !important;
}

.fc-col-header-cell {
  background-color: var(--ion-color-light);
  font-size: 0.9rem;
}

// // ---------------------

// //  DARK theme for fullcalendar

// // ---------------------

body.dark {

  // Month style

  .fc-daygrid-day {
    background-color: var(--app-color-component-background-primary);
  }

  .fc-day-disabled {
    background: var(--ion-color-dark) !important;
  }

  .fc-daygrid-day-top {
    background-color: #383838 !important;
  }

  // Month / Week / Day common style

  .fc-col-header-cell {
    background-color: var(--app-color-component-background-primary);
  }

  .fc-scrollgrid {
    border-color: var(--ion-color-medium);
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border-color: var(--ion-color-medium);
  }

  .fc-toolbar.fc-header-toolbar {
    border-bottom: 1px solid var(--ion-color-medium) !important;
  }
}
