/*!
 * Copyright ou © ou Copr. Université de Lorraine, (2022)
 *
 * Direction du Numérique de l'Université de Lorraine - SIED
 *  (dn-mobile-dev@univ-lorraine.fr)
 * JNESIS (contact@jnesis.com)
 *
 * Ce logiciel est un programme informatique servant à rendre accessible
 * sur mobile divers services universitaires aux étudiants et aux personnels
 * de l'université.
 *
 * Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 * et respectant les principes de diffusion des logiciels libres. Vous pouvez
 * utiliser, modifier et/ou redistribuer ce programme sous les conditions
 * de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 * sur le site "http://cecill.info".
 *
 * En contrepartie de l'accessibilité au code source et des droits de copie,
 * de modification et de redistribution accordés par cette licence, il n'est
 * offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 * seule une responsabilité restreinte pèse sur l'auteur du programme, le
 * titulaire des droits patrimoniaux et les concédants successifs.
 *
 * À cet égard, l'attention de l'utilisateur est attirée sur les risques
 * associés au chargement, à l'utilisation, à la modification et/ou au
 * développement et à la reproduction du logiciel par l'utilisateur étant
 * donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 * manipuler et qui le réserve donc à des développeurs et des professionnels
 * avertis possédant des connaissances informatiques approfondies. Les
 * utilisateurs sont donc invités à charger et à tester l'adéquation du
 * logiciel à leurs besoins dans des conditions permettant d'assurer la
 * sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 * à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 * Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 * pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 * termes.
 */

// Colors Variables and Ionic Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {

  /**  Our custom app colors variables **/

  // // // ---------------------
  // // // Font color variables
  // // // ---------------------

  --app-font-color-primary: #000000;
  --app-font-color-primary-medium: #1b1b1b;
  --app-font-color-primary-contrast: #ffffff;

  // The next colors are fixed and not inverted in dark theme
  // Don't change the next value in dark theme
  //______________________________________________

  // If a component have the same background in light/dark theme this variable are used (in font.scss)
  --app-font-fix-light-color: #ffffff;
  --app-font-fix-dark-color: #000000;

  /** Top bar **/
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: var(--ion-color-primary-contrast);
  --app-font-color-topbar: #ffffff; // Change in body.dark{...} only if ion-color-primary becomes light in dark theme
  ion-modal {
    --ion-toolbar-border-color: var(--ion-color-light-shade);
    --ion-toolbar-background: var(--ion-color-light);
    --ion-toolbar-color: var(--ion-color-light-contrast);
    ion-toolbar.app-toolbar-as-on-page {
      --ion-toolbar-border-color: var(--ion-color-step-150);
      --ion-toolbar-background: var(--ion-color-primary);
      --ion-toolbar-color: var(--ion-color-primary-contrast);
    }
  }

  /** progress bar **/
  ion-progress-bar {
    --background: var(--ion-color-medium-light);
    --progress-background: var(--ion-color-dark);
  }

  // If a widget have a particular background color set by the administrator in the CMS
  //  the app check if the background color is dark and put the contrasted font color bellow
  --app-font-color-for-dark-background-from-cms: #ffffff;
  --app-font-color-for-light-background-from-cms: #000000;
  //______________________________________________


  // // // ---------------------
  // // // Background variables (Change value here and use variables in SCSS files on all CSS classes needed throughout the app)
  // Example usage: .css-class { --ion-background-color: var(--app-component-background-primary) !important; }
  // // // ---------------------

  /** App background**/
  --app-color-background: #ffffff;

  /** Pages background  : If the background is different from the app background. **/
  // services.page in Features module
  --app-services-color-background: var(--ion-color-quaternary);

  /** Component background (if color is different from Ionic colors Theming below). Add colors if necessary**/
  --app-color-component-background-primary: #ffffff;
  --app-color-component-background-secondary: var(--ion-color-light);
  --app-color-component-background-tertiary: #383838;

  /** Box and button color variables: see app-theme-variables.scss (exception)

  /** Ionic CSS colors variables theming **/

  // ---------------------
  // Change value here with the Ionic Colors generator https://ionicframework.com/docs/theming/color-generator
  //  Two ways to use this variables :
  // - In SCSS files on all CSS classes needed throughout the app. Example: background-color: var(--ion-color-primary);
  // - On Ionic HTML components when they have a color property. Example: <ion-toolbar color="primary">
  // To add a new default color, please see below.
  // ---------------------

  /** primary **/
  --ion-color-primary: #e84e24;
  --ion-color-primary-rgb: 5, 3, 1;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #040301;
  --ion-color-primary-tint: #1e1c1a;

  /** secondary **/
  --ion-color-secondary: #1cbef3;
  --ion-color-secondary-rgb: 255, 239, 101;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0d259;
  --ion-color-secondary-tint: #fff174;

  /** tertiary **/
  --ion-color-tertiary: #a0d0b0;
  --ion-color-tertiary-rgb: 160, 208, 176;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #8db79b;
  --ion-color-tertiary-tint: #aad5b8;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #252525;
  --ion-color-dark-rgb: 37,37,37;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #212121;
  --ion-color-dark-tint: #3b3b3b;

  /** medium **/
  --ion-color-medium: #575760;
  --ion-color-medium-rgb: 87, 87, 96;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #4d4d54;
  --ion-color-medium-tint: #686870;

  /** light **/
  --ion-color-light: #f5f8fb;
  --ion-color-light-rgb: 247, 248, 249;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d9dadb;
  --ion-color-light-tint: #f8f9fa;

  /**
 * The next colors are added as a new Ionic default color parameter.
 * You can name it anything you want, for example: quaternary.
 * You can use it in a component just like the default Ionic colors above by specifying
 * the color attribute: <ion-toolbar color='quaternary'> or with var() in scss files
 */

  /** quaternary **/
  --ion-color-quaternary: #f6f0ed;
  --ion-color-quaternary-rgb: 186, 223, 238;
  --ion-color-quaternary-contrast: #000000;
  --ion-color-quaternary-contrast-rgb: 0, 0, 0;
  --ion-color-quaternary-shade: #a4c4d1;
  --ion-color-quaternary-tint: #c1e2f0;

  /** medium-light **/
  --ion-color-medium-light: #b2b2be;
  --ion-color-medium-light-rgb: 178, 178, 190;
  --ion-color-medium-light-contrast: #000000;
  --ion-color-medium-light-contrast-rgb: 0, 0, 0;
  --ion-color-medium-light-shade: #9d9da7;
  --ion-color-medium-light-tint: #babac5;

  /** highlight **/
  // Exemple of use : new item in a list (used in Notifications module for new notification)
  --ion-color-highlight: #1cbef3;
  --ion-color-highlight-rgb: 255, 239, 101;
  --ion-color-highlight-contrast: #000000;
  --ion-color-highlight-contrast-rgb: 0, 0, 0;
  --ion-color-highlight-shade: #e0d259;
  --ion-color-highlight-tint: #fff174;

  /** icon-color **/
  /**
  Icons can be from the assets folder, CMS with SVG code, or CMS with Ionic icon names only.
  For the last case and if icon svg files from assets and CMS doesn' have a fill="[color]" attribute the next colors are applied
  Use like this :
      <app-custom-icon
      ...
      [color]="(isDarkTheme$ | async) === true ? 'icon-primary': 'icon-primary-contrast'"
      ...></app-custom-icon>

  The classes can be inverted depending on the background color of the component where <app-custom-icon> is injected.
  **/
  --ion-color-icon-primary: #ffffff;
  --ion-color-icon-primary-contrast: #000000;
}

.ion-color-quaternary {
  --ion-color-base: var(--ion-color-quaternary) !important;
  --ion-color-base-rgb: var(--ion-color-quaternary-rgb) !important;
  --ion-color-contrast: var(--ion-color-quaternary-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-quaternary-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-quaternary-shade) !important;
  --ion-color-tint: var(--ion-color-quaternary-tint) !important;
}

.ion-color-medium-light {
  --ion-color-base: var(--ion-color-medium-light) !important;
  --ion-color-base-rgb: var(--ion-color-medium-light-rgb) !important;
  --ion-color-contrast: var(--ion-color-medium-light-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-medium-light-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-medium-light-shade) !important;
  --ion-color-tint: var(--ion-color-medium-light-tint) !important;
}

.ion-color-highlight {
  --ion-color-base: var(--ion-color-highlight) !important;
  --ion-color-base-rgb: var(--ion-color-highlight-rgb) !important;
  --ion-color-contrast: var(--ion-color-highlight-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-highlight-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-highlight-shade) !important;
  --ion-color-tint: var(--ion-color-highlight-tint) !important;
}

.ion-color-icon-primary {
  --ion-color-base: var(--ion-color-icon-primary) !important;
}

.ion-color-icon-primary-contrast {
  --ion-color-base: var(--ion-color-icon-primary-contrast) !important;
}





/*
   * Dark Theme Colors
   * -------------------------------------------
   */

body.dark {

  /**  Our custom app colors variables **/

  // // // ---------------------
  // // // Font color variables (Change value here and use variables only in css)
  // // // ---------------------

  --app-font-color-primary: #ffffff;
  --app-font-color-primary-medium: #f9f9f9;
  --app-font-color-primary-contrast: #000000;

  /** Top bar **/
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: var(--ion-color-primary-contrast);
  --app-font-color-topbar: #ffffff;
  ion-modal {
    --ion-toolbar-border-color: var(--ion-color-step-250);
    --ion-toolbar-background: #1f1f1f;
    --ion-toolbar-color: #ffffff;
    ion-toolbar.app-toolbar-as-on-page {
      --ion-toolbar-border-color: var(--ion-color-step-150);
      --ion-toolbar-background: var(--ion-color-primary);
      --ion-toolbar-color: var(--ion-color-primary-contrast);
    }
  }

  /** progress bar **/
  ion-progress-bar {
    --background: var(--ion-color-medium);
    --progress-background: var(--ion-color-medium-light);
  }

  // // // ---------------------
  // // // Background variables (Change value here and use variables in SCSS files on all CSS classes needed throughout the app)
  // Example usage: .css-class { --ion-background-color: var(--app-component-background-primary) !important; }
  // // // ---------------------

  /** App background **/
  --app-color-background: #383838;

  /** Pages background  : If the background is different from the app background. **/
  // services.page in Features module
  --app-services-color-background: var(--app-color-background);

  /** Component background (if color is different from Ionic colors Theming below). Add colors if necessary**/
  --app-color-component-background-primary: #383838;
  --app-color-component-background-secondary: var(--ion-color-dark);
  --app-color-component-background-tertiary: #383838;
  /** Ionic CSS colors variables theming **/

  // ---------------------
  // Change value here with the Ionic Colors generator https://ionicframework.com/docs/theming/color-generator
  //  Two ways to use this variables :
  // - In SCSS files on all CSS classes needed throughout the app. Example: background-color: var(--ion-color-primary);
  // - On Ionic HTML components when they have a color property. Example: <ion-toolbar color="primary">
  // To add a new default color, please see below.
  // ---------------------

  /** primary **/
  --ion-color-primary: #e84e24;
  --ion-color-primary-rgb: 5, 3, 1;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #040301;
  --ion-color-primary-tint: #1e1c1a;

  /** secondary **/
  --ion-color-secondary: #1cbef3;
  --ion-color-secondary-rgb: 255, 239, 101;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0d259;
  --ion-color-secondary-tint: #fff174;

  /** tertiary **/
  --ion-color-tertiary: #a0d0b0;
  --ion-color-tertiary-rgb: 160, 208, 176;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #8db79b;
  --ion-color-tertiary-tint: #aad5b8;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #252525;
  --ion-color-dark-rgb: 37,37,37;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #212121;
  --ion-color-dark-tint: #3b3b3b;

  /** medium **/
  --ion-color-medium: #575760;
  --ion-color-medium-rgb: 87, 87, 96;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #4d4d54;
  --ion-color-medium-tint: #686870;

  /** light **/
  --ion-color-light: #f5f8fb;
  --ion-color-light-rgb: 247, 248, 249;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d9dadb;
  --ion-color-light-tint: #f8f9fa;

  /**
 * The next colors are added as a new Ionic default color parameter.
 * You can name it anything you want, for example: quaternary.
 * You can use it in a component just like the default Ionic colors above by specifying
 * the color attribute: <ion-toolbar color='quaternary'> or with var() in scss files
 */

  /** quaternary **/
  --ion-color-quaternary: #f6f0ed;
  --ion-color-quaternary-rgb: 186, 223, 238;
  --ion-color-quaternary-contrast: #000000;
  --ion-color-quaternary-contrast-rgb: 0, 0, 0;
  --ion-color-quaternary-shade: #a4c4d1;
  --ion-color-quaternary-tint: #c1e2f0;

  /** medium-light **/
  --ion-color-medium-light: #b2b2be;
  --ion-color-medium-light-rgb: 178, 178, 190;
  --ion-color-medium-light-contrast: #000000;
  --ion-color-medium-light-contrast-rgb: 0, 0, 0;
  --ion-color-medium-light-shade: #9d9da7;
  --ion-color-medium-light-tint: #babac5;

  /** highlight **/
  // Exemple of use : new item in a list (used in Notifications module for new notification)
  --ion-color-highlight: #5e5503;
  --ion-color-highlight-rgb: 255, 239, 101;
  --ion-color-highlight-contrast: #000000;
  --ion-color-highlight-contrast-rgb: 0, 0, 0;
  --ion-color-highlight-shade: #e0d259;
  --ion-color-highlight-tint: #fff174;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body.dark {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;

  .button-solid {
    --background: rgb(255, 255, 255);
  }

  .alert-button.sc-ion-alert-ios:last-child {
    color: white;
  }
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
}


/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;

  .button-solid {
    --background: rgb(255, 255, 255);
  }

  .alert-button.sc-ion-alert-md:last-child {
    color: white;
  }
}
