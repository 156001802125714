/*!
 * Copyright ou © ou Copr. Université de Lorraine, (2022)
 *
 * Direction du Numérique de l'Université de Lorraine - SIED
 *  (dn-mobile-dev@univ-lorraine.fr)
 * JNESIS (contact@jnesis.com)
 *
 * Ce logiciel est un programme informatique servant à rendre accessible
 * sur mobile divers services universitaires aux étudiants et aux personnels
 * de l'université.
 *
 * Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 * et respectant les principes de diffusion des logiciels libres. Vous pouvez
 * utiliser, modifier et/ou redistribuer ce programme sous les conditions
 * de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 * sur le site "http://cecill.info".
 *
 * En contrepartie de l'accessibilité au code source et des droits de copie,
 * de modification et de redistribution accordés par cette licence, il n'est
 * offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 * seule une responsabilité restreinte pèse sur l'auteur du programme, le
 * titulaire des droits patrimoniaux et les concédants successifs.
 *
 * À cet égard, l'attention de l'utilisateur est attirée sur les risques
 * associés au chargement, à l'utilisation, à la modification et/ou au
 * développement et à la reproduction du logiciel par l'utilisateur étant
 * donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 * manipuler et qui le réserve donc à des développeurs et des professionnels
 * avertis possédant des connaissances informatiques approfondies. Les
 * utilisateurs sont donc invités à charger et à tester l'adéquation du
 * logiciel à leurs besoins dans des conditions permettant d'assurer la
 * sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 * à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 * Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 * pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 * termes.
 */

// // ---------------------
// // Font css classes (Use these CSS classes in HTML on all text tags throughout the app).
// // ---------------------

/* HOW TO USE VARIABLES

   Don't use the color attribute of Ionic components on ion-label tags and don't use a class bellow
   on ion-button or on a parent of a text tag.

   How not to do it: <ion-label color="primary"> <h1> text </h1> <p> text </p> </ion-label>
   How not to do it: <ion-button class="app-text-1"> text </ion-button>

   How to do it: Use the following font classes directly on the text tags.
   - <ion-label> <h1 class="app-title-1"></h1> <p class="app-text-1"></p> </ion-label>
   - <ion-button> <ion-text class="app-text-1"> text </ion-text> </ion-button>
   Because Ionic handles the styling for tags inside ion-label and for the text inside ion-button. */

/* DANGER VARIABLE

   At the bottom of the file, you will find the .danger class color (use this class in addition to the other classes above).
   Don't use the color="danger" attribute, but instead use: <ion-text class="app-title-1 light danger"> */


/* FIXED FONT COLOR VARIABLES (fix color even if light or dark theme)

   At the bottom of the file : you will find classes fixed-light/dark-color. Use with app-text-. app-title-. classes if
   the font color must be the same in light and dark theme. Typicaly if a background component is the
   same in light and dark theme the font color must be fixed and this classes are used. */

// // // --------------------
// // // Title level
// // // ---------------------

.app-title-1 {
  font-size: var(--app-title-1-size) !important;
  font-family: var(--app-font-primary-static-bold) !important;
  color: var(--app-font-color-primary) !important;
  text-transform: uppercase;
}

.app-title-1.light {
  font-family: var(--app-font-primary-static-light) !important;
  text-transform: none;
}

.app-title-1.strong {
  font-weight: bold !important;
}

.app-title-1.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// Title-2
.app-title-2 {
  font-size: var(--app-title-2-size) !important;
  font-family: var(--app-font-primary-static-bold) !important;
  color: var(--app-font-color-primary) !important;
  text-transform: uppercase;
}

.app-title-2.light {
  font-family: var(--app-font-primary-static-light) !important;
  text-transform: none;
}

.app-title-2.strong {
  font-weight: bold !important;
}

.app-title-2.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// Title-3
.app-title-3 {
  font-size: var(--app-title-3-size) !important;
  font-family: var(--app-font-primary-static-bold) !important;
  color: var(--app-font-color-primary) !important;
  text-transform: uppercase;
}

.app-title-3.light {
  font-family: var(--app-font-primary-static-light) !important;
  text-transform: none;
}

.app-title-3.strong {
  font-weight: bold !important;
}

.app-title-3.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// Title-4
.app-title-4 {
  font-size: var(--app-title-4-size) !important;
  font-family: var(--app-font-primary-static-bold) !important;
  color: var(--app-font-color-primary) !important;
  text-transform: uppercase;
}

.app-title-4.light {
  font-family: var(--app-font-primary-static-light) !important;
  text-transform: none;
}

.app-title-4.strong {
  font-weight: bold !important;
}

.app-title-4.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// Title-5
.app-title-5 {
  font-size: var(--app-title-5-size) !important;
  font-family: var(--app-font-primary-static-bold) !important;
  color: var(--app-font-color-primary) !important;
  text-transform: uppercase;
}

.app-title-5.light {
  font-family: var(--app-font-primary-static-light) !important;
  text-transform: none;
}

.app-title-5.strong {
  font-weight: bold !important;
}

.app-title-5.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// Title-6
.app-title-6 {
  font-size: var(--app-title-6-size) !important;
  font-family: var(--app-font-primary-static-bold) !important;
  color: var(--app-font-color-primary) !important;
  text-transform: uppercase;
}

.app-title-6.light {
  font-family: var(--app-font-primary-static-light) !important;
  text-transform: none;
}

.app-title-6.strong {
  font-weight: bold !important;
}

.app-title-6.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// Title-7
.app-title-7 {
  font-size: var(--app-title-7-size) !important;
  font-family: var(--app-font-primary-static-bold) !important;
  color: var(--app-font-color-primary) !important;
  text-transform: uppercase;
}

.app-title-7.light {
  font-family: var(--app-font-primary-static-light) !important;
  text-transform: none;
}

.app-title-7.strong {
  font-weight: bold !important;
}

.app-title-7.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// // // ---------------------
// // // Text level
// // // ---------------------

// Text-1
.app-text-1 {
  font-size: var(--app-text-1-size) !important;
  font-family: var(--app-font-secondary) !important;
  color: var(--app-font-color-primary-medium) !important;
  font-weight: 500 !important;
}

.app-text-1.light {
  font-weight: normal !important;
}

.app-text-1.strong {
  font-weight: bold !important;
}

.app-text-1.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// Text-2
.app-text-2 {
  font-size: var(--app-text-2-size) !important;
  font-family: var(--app-font-secondary) !important;
  color: var(--app-font-color-primary-medium) !important;
  font-weight: 500 !important;
}

.app-text-2.light {
  font-weight: normal !important;
}

.app-text-2.strong {
  font-weight: bold !important;
}

.app-text-2.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// Text-3
.app-text-3 {
  font-size: var(--app-text-2-size) !important;
  font-family: var(--app-font-secondary) !important;
  color: var(--app-font-color-primary-medium) !important;
  font-weight: 500 !important;
}

.app-text-3.light {
  font-weight: normal !important;
}

.app-text-3.strong {
  font-weight: bold !important;
}

.app-text-3.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// Text-4
.app-text-4 {
  font-size: var(--app-text-4-size) !important;
  font-family: var(--app-font-secondary) !important;
  color: var(--app-font-color-primary-medium) !important;
  font-weight: 500 !important;
}

.app-text-4.light {
  font-weight: normal !important;
}

.app-text-4.strong {
  font-weight: bold !important;
}

.app-text-4.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// Text-5
.app-text-5 {
  font-size: var(--app-text-5-size) !important;
  font-family: var(--app-font-secondary) !important;
  color: var(--app-font-color-primary-medium) !important;
  font-weight: 500 !important;
}

.app-text-5.light {
  font-weight: normal !important;
}

.app-text-5.strong {
  font-weight: bold !important;
}

.app-text-5.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

// Text-6
.app-text-6 {
  font-size: var(--app-text-6-size) !important;
  font-family: var(--app-font-secondary) !important;
  color: var(--app-font-color-primary-medium) !important;
  font-weight: 500 !important;
}

.app-text-6.light {
  font-weight: normal !important;
}

.app-text-6.strong {
  font-weight: bold !important;
}

.app-text-6.contrast {
  color: var(--app-font-color-primary-contrast) !important;
}

//________________________________________________________

.app-fixed-light-color {
  color: var(--app-font-fix-light-color) !important;
}

.app-fixed-dark-color {
  color: var(--app-font-fix-dark-color) !important;
}

// danger color (use this class in adition with other class above.
// Don't use the color="danger" attribute, but instead use: <ion-text class="app-title-1 light danger">
.danger {
  color: var(--ion-color-danger) !important;
}

// This class can be used to force the links rendered inside innerHTML to inherit the color of their container
.link-inherit-color a {
  color: inherit !important;
}
